@import '../variables/colors_new';
@import '../base/icons'; //generated by gulp
@import '../components-v3/mixins/mediaquerries';
@import '../components-v3/mixins/flexbox';
@import '../components-v3/mixins/css-grid';
@import '../components-v3/variables/_variables';


#indi-banner {
  .o-stretch-wrapper {
    width: 100%;
  }

  
  .c-banner {
    font-family: Flanders Art Sans, 'Myriad Pro', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 1.125rem;
    line-height: 27px;
    margin: 0;
    text-align: left;
    color: #474b50;

    p {
      margin-bottom: calc(27px/2);
    }
  
    ul {
      margin: 0;
    }
  
    li {
      margin-top: calc(1.6875rem/2);
      margin-bottom: calc(1.6875rem/2);
    }

    a[class*=action-] {
      position: relative;
      text-decoration: none;
      color: #2d89cc;
    }

    h2, h3, h4, h5 .c-h2, .c-h3, .c-h4, .c-h5, .h2, .h3, .h4, .h5 {
      font-weight: 500;
      color: $color-indigo-900;

    }

    h2, .h2, .c-h2 {
      @include tablet {
        font-size: 1.75rem; //28px
        line-height: 1.6875rem*1.2;
        margin-top: 1.6875rem*1.2;
      }
      font-size: 2rem; //32px
      line-height: 1.6875rem*1.5; // 40.5px
      margin-top: 1.6875rem*1.5;
    
    
      &:first-child {
        margin-top: 0;
      }
    
    }

    h3, .h3, .c-h3 {
      font-size: 1.625rem; //26px
      line-height: 1.6875rem*1.2; // 28.8 = 1,18
      margin-top: 1.6875rem;
    
      &:first-child {
        margin-top: 0;
      }
    }

    
    h4, .h4, .c-h4 {
      font-size: 1.375rem;
      line-height: 1.6875rem;
      margin-bottom: 0;
    
      &:first-child {
        margin-top: 0;
      }
    }

    h5, .h5, .c-h5 {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      margin-bottom: 0;

      &:first-child {
        margin-top: 0;
      }
    }
    
    .u-m0 {
      margin: 0 !important;
    }

    .o-wrapper {
      box-sizing: border-box;
      max-width: $wrapper-max-width;
      margin: 0 auto; 
    }
    
  
    &.-warning {
      background-color: $color-orange-200;
    }
  
    &.-info {
      background-color: $color-blue-200;
    }

    &.-success {
      background-color: $color-lightgreen-200;
    }

    &__container {
      @include display-grid();
      padding: $spacing-s 0;

      grid-template-columns: 2.5rem auto;
      grid-gap: $spacing-s;
  
    }
  
    &__icon {
      

      &.-wrench:before {
        font-family: icons;
        font-size: 40px;
        font-weight: 400;
        font-style: normal;
        line-height: 1;
        display: inline-block;
        content: char-from-code($icon-fa-wrench-light);
        vertical-align: middle;
        text-decoration: inherit;
        text-transform: none;

        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &.-info:before {
        font-family: icons;
        font-size: 40px;
        font-weight: 400;
        font-style: normal;
        line-height: 1;
        display: inline-block;
        content: char-from-code($icon-fa-info-circle-light);
        vertical-align: middle;
        text-decoration: inherit;
        text-transform: none;

        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
  
      }

      &.-warning:before {
        font-family: icons;
        font-size: 40px;
        font-weight: 400;
        font-style: normal;
        line-height: 1;
        display: inline-block;
        content: char-from-code($icon-warning-light);
        vertical-align: middle;
        text-decoration: inherit;
        text-transform: none;

        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
  
      }
    }

  
    &__text {
      line-height: 22px;
      max-width: 800px;
    }
  
    &__ctas {
      @include flexbox();
      @include align-items(center);

      gap: $spacing-s;
    }
  
    &__form {
      @include flexbox();
      @include align-items(center);
      width: auto;

      gap: $spacing-s;
    }
  
    &__info-icon {
      display: none;
    }
  }
}


@include screenLarge {
  #indi-banner .c-banner {
    .o-wrapper {
      margin-right: $spacing-m;
      margin-left: $spacing-m;
    }
  }
}
@include screen() {
  #indi-banner .c-banner {
    &__content {
      @include flex-direction(column);
    }
  }
}
@include tablet() {
  #indi-banner .c-banner {
    &__container {
      grid-template-columns: auto;
    }

    &__icon {
      display: none;
      
    }

    &__info-icon {
      display: revert;
    }

    &__form {
      flex-wrap: wrap;
    }
  }
}
@include phone() {
  #indi-banner .c-banner {
    &__content, &__form {
      gap: $spacing-xs;
    }
  }
}